<script setup lang="ts">
import { computed } from 'vue'

import VoixSliceWrapper from '@voix/components/slice/VoixSliceWrapper.vue'

import type { ElementInterface, GroupInterface, QuerySliceInterface, SliceConfigInterface, SliceInterface } from '@voix/types'
import { useSliceStore } from '@voix/store/sliceStore'
import VoixElementsLoop from './VoixElementsLoop.vue'
import VoixQuerySlice from './VoixQuerySlice.vue'

const props = defineProps<{ element: ElementInterface | SliceInterface | GroupInterface | QuerySliceInterface }>()

const sliceStore = useSliceStore()
const voixSliceConfigurations = computed(() => {
  const sliceConfiguration = sliceStore.slices[props.element.component as string] as SliceConfigInterface
  if (!sliceConfiguration) {
    console.error(`Slice ${props.element.component} not found`)
    return null
  }
  return sliceConfiguration
})

function getSlotElements(slot: string) {
  return props.element.elements?.filter((el) => {
    if (!el.slot && slot === 'default')
      return true

    return el.slot === slot
  })
}

function setGroupElement(element: ElementInterface) {
  return element as GroupInterface
}
function setQuerySliceElement(element: ElementInterface) {
  return element as QuerySliceInterface
}
</script>

<template>
  <template v-if="props.element && props.element.type === 'slice'">
    <VoixSliceWrapper
      :when-visible="!!(props.element.hydration_mode && props.element.hydration_mode === 'visible')"
      :ssr-only="element.hydration_mode === 'none'"
      :load="element.hydration_mode === 'load'"
      :client-only="props.element.hydration_mode === 'client-only'"
      :when-idle="!element.hydration_mode || props.element.hydration_mode === 'idle'"
    >
      <VoixSlice
        v-if="voixSliceConfigurations"
        :slice="element as SliceInterface"
      >
        <template
          v-for="{ name } in voixSliceConfigurations.slots"
          :key="name"
          #[name]
        >
          <VoixElementsLoop :elements="getSlotElements(name)" />
        </template>
      </VoixSlice>
    </VoixSliceWrapper>
  </template>

  <VoixQuerySlice
    v-if="props.element.type === 'query'"
    :element="props.element as QuerySliceInterface"
  />
</template>
