<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent, ref, watch } from 'vue'

type VoidFunction = () => void

function isBrowser() {
  return typeof window === 'object'
}
export default defineComponent({
  props: {
    ssrOnly: Boolean,
    clientOnly: Boolean,
    whenIdle: Boolean,
    load: Boolean,
    whenVisible: [Boolean, Object] as PropType<boolean | IntersectionObserverInit>,
    didHydrate: Function as PropType<() => void>,
    promise: Object as PropType<Promise<any>>,
    on: [Array, String] as PropType<
      (keyof HTMLElementEventMap)[] | keyof HTMLElementEventMap
    >,
  },
  setup(props) {
    const noOptions
      = !props.ssrOnly
      && !props.whenIdle
      && !props.whenVisible
      && !props.on?.length
      && !props.promise
    const wrapper = ref<Element | null>(null)
    const hydrated = ref(noOptions)
    const hydrate = () => {
      hydrated.value = true
    }

    watch(
      hydrated,
      (hydrate) => {
        if (hydrate && props.didHydrate)
          props.didHydrate()
      },
      { immediate: true },
    )
    watch(
      [() => props, wrapper, hydrated],
      (
        [{ on, promise, ssrOnly, whenIdle, whenVisible }, wrapper, hydrated],
        _,
        onInvalidate,
      ) => {
        // If it's already been hydrated or there is no wrapper then just ignore
        if (hydrated || !wrapper)
          return

        if (ssrOnly && !isBrowser())
          return hydrate()

        const cleanupFns: VoidFunction[] = []
        const cleanup = () => {
          cleanupFns.forEach((fn) => {
            fn()
          })
        }

        if (promise)
          promise.then(hydrate, hydrate)

        if (whenVisible && isBrowser()) {
          // TODO: This is a hack to make sure the element is pushed down the page
          // far enough so it doesn't trigger the IntersectionObserver immediately
          setTimeout(() => {
            if (wrapper && typeof IntersectionObserver !== 'undefined') {
              const observerOptions
              = typeof whenVisible === 'object'
                ? whenVisible
                : {
                    rootMargin: '0px',
                    threshold: 0,
                  }

              const callback = (entries: Array<any>) => {
                entries.forEach((entry) => {
                  if (entry.isIntersecting)
                    hydrate()
                })
              }

              const io = new IntersectionObserver(callback, observerOptions)

              io.observe(wrapper as Element)

              cleanupFns.push(() => {
                io.disconnect()
              })
            }
            else {
              return hydrate()
            }
          }, 500)
        }

        if (whenIdle) {
          if (typeof window.requestIdleCallback !== 'undefined') {
            const idleCallbackId = window.requestIdleCallback(hydrate, {
              timeout: 500,
            })
            cleanupFns.push(() => {
              window.cancelIdleCallback(idleCallbackId)
            })
          }
          else {
            const id = setTimeout(hydrate, 2000)
            cleanupFns.push(() => {
              clearTimeout(id)
            })
          }
        }

        if (on) {
          const events = ([] as Array<keyof HTMLElementEventMap>).concat(on)

          events.forEach((event) => {
            wrapper?.addEventListener(event, hydrate, {
              once: true,
              passive: true,
            })
            cleanupFns.push(() => {
              wrapper?.removeEventListener(event, hydrate, {})
            })
          })
        }

        onInvalidate(cleanup)
      },
      { immediate: true },
    )
    return {
      wrapper,
      hydrated,
    }
  },
})
</script>

<template>
  <template v-if="ssrOnly">
    <slot />
  </template>
  <template v-else>
    <slot v-if="hydrated" />
    <div v-else ref="wrapper" />
  </template>
</template>
