<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, provide } from 'vue'

import { usePageStore } from '@voix/store/pageStore'
import VoixElementsLoop from './VoixElementsLoop.vue'

const pageStore = usePageStore()

function kebabCase(string: string) {
  return string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()
}

const layout = computed(() => {
  if (pageStore.currentPage)
    return kebabCase(pageStore.currentPage.layout)

  return null
})

provide('voixLayout', layout)

onMounted(() => {
// TODO: Trying to notify user we're leaving the page
  // if (typeof window !== 'undefined' && window.parent) {
  //   // Add event listener to notify the admin of any URL changes so we can intercept them
  //   window.addEventListener('beforeunload', notifyAdminOfUrlChange)
  // }
})
onBeforeUnmount(() => {
  // TODO: Trying to be efficient and ahead of myself
  // if (typeof window !== 'undefined' && window.parent) {
  //   // Add event listener to notify the admin of any URL changes so we can intercept them
  //   window.removeEventListener('beforeunload', notifyAdminOfUrlChange)
  // }
})

// function notifyAdminOfUrlChange(event) {
//   event.preventDefault()
//   event.returnValue = ''
//   console.log(event)
//   // Send a message to the parent window of the url change
//   window.parent.postMessage({ type: 'voixUrlChange', url: event.data.url }, '*')
// }

// TODO: look at pageResponse.response.status and display the correct thing when it's a 404
</script>

<template>
  <div>
    <NuxtLayout v-if="layout && pageStore.currentPage" :name="layout">
      <VoixElementsLoop
        :elements="pageStore.currentPage.elements"
      />
    </NuxtLayout>

    <ClientOnly>
      <LazyVoixEditorShortcut />
    </ClientOnly>
  </div>
</template>
