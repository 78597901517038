<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import type { PropType, StyleValue } from 'vue'

import { useDebounceFn, useWindowSize } from '@vueuse/core'

import {
  buildColorStyles,
  buildFlexStyles,
  buildGridStyles,
  buildMarginStyles,
  buildMaxWidthStyles,
  buildPaddingStyles,
} from '../../composables/useStyle'
import { findBreakpointSettings } from '../../composables/useBreakpoints'

import { usePageStore } from '../../store/pageStore'

import type {
  ElementInterface,
  QuerySettingsInterface,
  QuerySliceInterface,
  SliceInterface,
} from '../../types'

export default defineComponent({
  name: 'VoixQuerySlice',

  props: {
    element: {
      type: Object as PropType<QuerySliceInterface>,
      required: true,
    },
  },

  setup(props) {
    const root = ref<HTMLElement>()
    const queryWrapperStyles = ref<StyleValue>()
    const queryWrapperClasses = ref<object>([])
    const pageStore = usePageStore()

    const generateQueryWrapperStyles = () => {
      let theQuerySlice = Object.assign({}, props.element)

      if (props.element.id) {
        theQuerySlice = pageStore.findElement(props.element.id)
          ?.element as QuerySliceInterface
      }

      if (!theQuerySlice)
        return { properties: { settings: {} } }

      let styles: StyleValue = {}

      if (theQuerySlice.properties.settings) {
        const breakpointSettings = findBreakpointSettings(
          theQuerySlice.properties.settings,
        ) as QuerySettingsInterface | null

        if (breakpointSettings) {
          if (breakpointSettings.position)
            styles.position = breakpointSettings.position

          styles.display = breakpointSettings.displayMode?.toString()

          styles = buildFlexStyles(styles, breakpointSettings)
          styles = buildGridStyles(styles, breakpointSettings)
          styles = buildMaxWidthStyles(styles, breakpointSettings)
          styles = buildMarginStyles(styles, breakpointSettings)
          styles = buildPaddingStyles(styles, breakpointSettings)
          styles = buildColorStyles(styles, breakpointSettings)
        }
      }

      queryWrapperStyles.value = styles
    }

    generateQueryWrapperStyles()

    // Debounced updates to styles
    const updateQueryWrapperStyles = useDebounceFn(
      generateQueryWrapperStyles,
      10,
      { maxWait: 50 },
    )

    const generateQueryWrapperClasses = () => {
      const classes = {} as { container?: boolean }
      let theQuerySlice = Object.assign({}, props.element)

      if (props.element.id) {
        theQuerySlice = pageStore.findElement(props.element.id)
          ?.element as QuerySliceInterface
      }

      if (!theQuerySlice)
        return { properties: { settings: {} } }

      const breakpointSettings = theQuerySlice.properties.settings
        ? (findBreakpointSettings(
            theQuerySlice.properties.settings,
          ) as QuerySettingsInterface)
        : null

      if (breakpointSettings)
        classes.container = breakpointSettings.contain

      return classes
    }

    const updateQueryWrapperClasses = useDebounceFn(
      generateQueryWrapperClasses,
      10,
      { maxWait: 50 },
    )

    const { width: windowWidth, height: windowHeight } = useWindowSize()
    watch([windowWidth, windowHeight], () => {
      updateQueryWrapperStyles()
      updateQueryWrapperClasses()
    })

    watch(
      props,
      (value) => {
        if (value) {
          queryWrapperStyles.value = generateQueryWrapperStyles()
          queryWrapperClasses.value = generateQueryWrapperClasses()
        }
      },
      { deep: true },
    )

    const setSliceElement = (element: ElementInterface) => {
      return element as SliceInterface
    }

    return {
      props,
      root,
      queryWrapperStyles,
      queryWrapperClasses,
      setSliceElement,
    }
  },
})
</script>

<template>
  <div
    :id="element.properties.id"
    ref="root"
    :style="queryWrapperStyles"
    :class="queryWrapperClasses"
  >
    <template v-for="(childElement, key) in element.elements" :key="key">
      <VoixSliceLoop :element="childElement" />
    </template>
  </div>
</template>
