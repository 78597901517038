<script setup lang="ts"></script>

<template>
  <div>
    <VoixSiteAuth>
      <VoixPage>
        <VoixRoot />
      </VoixPage>
    </VoixSiteAuth>
  </div>
</template>
