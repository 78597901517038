import { usePageStore } from '@voix/store/pageStore'

import type { HeadTagInterface } from '@voix/types'

interface HeadDataInterface {
  link: Array<{ rel: string; href: string }>
  style: Array<{ rel: string; href: string }>
  script: Array<{ type: string; innerHTML?: string; src?: string; position?: 'bodyOpen' | 'bodyClose' | 'head' }>
  noscript: Array<{ type: string; innerHTML: string }>
}

function getUniqueTags(tags: Array<HeadTagInterface>) {
  const usedTypes: Array<string> = []
  return tags.filter((tag) => {
    if (!usedTypes.includes(tag.type)) {
      // it's not in the array yet so lets push and keep
      usedTypes.push(tag.type)
      return true
    }
    return false
  })
}

function addCanonical(head: HeadDataInterface) {
  const pageStore = usePageStore()
  const pageCanonical = pageStore.currentPage?.head_tags.find((x: HeadTagInterface) => x.type === 'canonical')?.content

  if (pageCanonical) {
    return head.link.push({
      rel: 'canonical',
      href: pageCanonical,
    })
  }
}

function addStructuredDateMarkup(head: HeadDataInterface) {
  const pageStore = usePageStore()
  if (pageStore.currentPage) {
    const pageTagsOnly: Array<HeadTagInterface> = pageStore.currentPage.head_tags.filter((x: HeadTagInterface) => x.type === 'structuredDataMarkup')
    const globalTagsOnly: Array<HeadTagInterface> = pageStore.currentPage?.global_head_tags.filter((x: HeadTagInterface) => x.type === 'structuredDataMarkup')

    const allTags = [...pageTagsOnly, ...globalTagsOnly]

    const finalTags = allTags.map((tag) => {
      return {
        type: 'application/ld+json',
        innerHTML: tag.content,
        tagPosition: tag.position,
      }
    })

    head.script = [...head.script, ...finalTags]
  }
}

function addScripts(head: HeadDataInterface) {
  const pageStore = usePageStore()
  if (pageStore.currentPage) {
    const pageTagsOnly: Array<HeadTagInterface> = pageStore.currentPage.head_tags.filter((x: HeadTagInterface) => x.type === 'script')
    const globalTagsOnly: Array<HeadTagInterface> = pageStore.currentPage?.global_head_tags.filter((x: HeadTagInterface) => x.type === 'script')

    const allTags = [...pageTagsOnly, ...globalTagsOnly]

    const finalTags = allTags.map((tag) => {
      return {
        type: tag.is_partytown ? 'text/partytown' : tag.type,
        innerHTML: tag.content,
        tagPosition: tag.position,
      }
    })

    head.script = [...head.script, ...finalTags]
  }
}

function addExternalScripts(head: HeadDataInterface) {
  const pageStore = usePageStore()
  if (pageStore.currentPage) {
    const pageTagsOnly: Array<HeadTagInterface> = pageStore.currentPage.head_tags.filter((x: HeadTagInterface) => x.type === 'externalScript')
    const globalTagsOnly: Array<HeadTagInterface> = pageStore.currentPage?.global_head_tags.filter((x: HeadTagInterface) => x.type === 'externalScript')

    const allTags = [...pageTagsOnly, ...globalTagsOnly]

    const finalTags = allTags.map((tag) => {
      return {
        type: tag.is_partytown ? 'text/partytown' : tag.type,
        src: tag.content,
        tagPosition: tag.position,
      }
    })

    head.script = [...head.script, ...finalTags]
  }
}

function addNoscripts(head: HeadDataInterface) {
  const pageStore = usePageStore()
  if (pageStore.currentPage) {
    const pageTagsOnly: Array<HeadTagInterface> = pageStore.currentPage.head_tags.filter((x: HeadTagInterface) => x.type === 'noscript')
    const globalTagsOnly: Array<HeadTagInterface> = pageStore.currentPage?.global_head_tags.filter((x: HeadTagInterface) => x.type === 'noscript')

    const allTags = [...pageTagsOnly, ...globalTagsOnly]

    const finalTags = allTags.map((tag) => {
      return {
        type: 'noscript',
        innerHTML: tag.content,
        tagPosition: tag.position,
      }
    })

    head.noscript = [...head.noscript, ...finalTags]
  }
}

function buildHeadData() {
  const head: HeadDataInterface = {
    link: [],
    style: [],
    script: [],
    noscript: [],
  }

  addCanonical(head)

  addStructuredDateMarkup(head)

  addScripts(head)

  addExternalScripts(head)

  addNoscripts(head)

  return head
}

function buildSeoMetaData() {
  const meta: { [key: string]: string } = {}
  const pageStore = usePageStore()

  if (pageStore.currentPage) {
    const nonMetaTypes = ['canonical', 'script', 'externalScript', 'style', 'externalStyle', 'structuredDataMarkup', 'noscript']
    const pageMetaTagsOnly: Array<HeadTagInterface> = pageStore.currentPage?.head_tags.filter((x: HeadTagInterface) => !nonMetaTypes.includes(x.type))
    const globalMetaTagsOnly: Array<HeadTagInterface> = pageStore.currentPage?.global_head_tags.filter((x: HeadTagInterface) => !nonMetaTypes.includes(x.type))

    const allMetaTags = [...pageMetaTagsOnly, ...globalMetaTagsOnly]

    const filteredMetaTags = getUniqueTags(allMetaTags)

    filteredMetaTags.forEach((tag) => {
      meta[tag.type] = tag.content
    })
  }

  return meta
}

export {
  buildHeadData,
  buildSeoMetaData,
}
