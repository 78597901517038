<script setup lang="ts">
import { reactive } from 'vue'
import { useRoute } from 'vue-router'
import { getCurrentPage } from '@voix/composables/queries/useQueryCurrentPage'
import { useVoixConfig } from '@voix/composables/useVoixConfig'
import { buildHeadData, buildSeoMetaData } from '@voix/composables/useHeadTagsBuilder'

import { usePageStore } from '@voix/store/pageStore'
import { createError, navigateTo, useHead, useSeoMeta } from '#imports'

const route = useRoute()
const pageStore = usePageStore()

let path = route.path
if (path !== '/' && path.startsWith('/'))
  path = path.substring(1, path.length)

const { getCurrentDomain } = useVoixConfig()
const currentDomain = getCurrentDomain()

// If the path isn't set or the domain wasn't found, throw a 404
if (!path || !currentDomain) {
  throw createError({
    fatal: true,
    statusCode: 404,
  })
}

const setHeadData = function () {
  if (pageStore.currentPage) {
    useSeoMeta(buildSeoMetaData())
    useHead(buildHeadData())
  }
}

// Get the current page
async function loadPage() {
  await getCurrentPage(
    reactive({
      path,
    }),
  )
  if (
    pageStore.pageResponse.redirect === null
    && pageStore.pageResponse.page === null
    && pageStore.pageResponse.status === 404
  ) {
    throw createError({
      fatal: true,
      statusCode: 404,
    })
  }

  if (
    pageStore.pageResponse.page === null
    && (pageStore.pageResponse.status === 301 || pageStore.pageResponse.status === 302)
  ) {
    const queryParams = new URLSearchParams(route.query).toString()
    const redirectTo = `${pageStore.pageResponse.redirect.to}?${queryParams}`
    const isExternal = redirectTo.startsWith('http') || redirectTo.startsWith('//')
    await navigateTo(redirectTo, { external: isExternal, replace: true, redirectCode: pageStore.pageResponse.status })
  }
}

await loadPage()
setHeadData()

function logError(error: any) {
  return error
}
</script>

<template>
  <div>
    <NuxtErrorBoundary @error="logError">
      <slot v-if="pageStore.currentPage" />
      <div v-else class="flex justify-center">
        <div class="flex flex-col space-y-4 bg-gray-100 text-gray-600 py-8 px-24 rounded-lg ">
          <span class="text-3xl font-thin">No page found.</span>
          <button class="bg-gray-600 text-white p-2 rounded" @click="loadPage">
            Load page again
          </button>
        </div>
      </div>
    </NuxtErrorBoundary>
  </div>
</template>
