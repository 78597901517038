<script setup lang="ts">
import type { ElementInterface, GroupInterface, QuerySliceInterface } from '@voix/types'
import type { PropType } from 'vue'

defineProps({
  elements: {
    type: Array as PropType<ElementInterface[]>,
    required: true,
  },
})

function setGroupElement(element: ElementInterface) {
  return element as GroupInterface
}
function setQuerySliceElement(element: ElementInterface) {
  return element as QuerySliceInterface
}
</script>

<template>
  <div
    v-for="(element) in elements"
    :key="element.id"
  >
    <template v-if="element.type === 'slice'">
      <VoixSliceLoop :element="element" />
    </template>
    <VoixGroup
      v-if="element.type === 'group'"
      :group="setGroupElement(element)"
    />
    <VoixQuerySlice v-if="element.type === 'query'" :element="setQuerySliceElement(element)" />
  </div>
</template>
