<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, ref, watch } from 'vue'
import type { PropType, StyleValue } from 'vue'

import { useDebounceFn, useWindowSize } from '@vueuse/core'

import type {
  ElementInterface,
  GroupInterface,
  GroupSettingsInterface,
  SliceInterface,
} from '@voix/types'

import {
  buildColorStyles,
  buildFlexStyles,
  buildGridStyles,
  buildMarginStyles,
  buildMaxWidthStyles,
  buildPaddingStyles,
} from '../../composables/useStyle'
import { findBreakpointSettings } from '../../composables/useBreakpoints'

import { usePageStore } from '../../store/pageStore'

const VoixGroupChild = defineAsyncComponent(
  // We have to type as Any due to the recursion and typescript

  () => import('../../components/page/VoixGroup.vue') as any,
)

export default defineComponent({
  name: 'VoixGroup',

  components: {
    VoixGroupChild,
  },

  props: {
    group: {
      type: Object as PropType<GroupInterface>,
      required: true,
    },
  },

  setup(props) {
    const root = ref<HTMLElement>()
    const groupStyles = ref<StyleValue>()
    const groupClasses = ref({})
    const pageStore = usePageStore()

    const groupId = computed(() => {
      return `voix-group-${props.group.id}`
    })

    function generateGroupStyles() {
      let theGroup = Object.assign({}, props.group)

      if (props.group.id)
        theGroup = pageStore.findElement(props.group.id)?.element as GroupInterface

      if (!theGroup)
        return { properties: { settings: {} } }

      let styles: Record<string, string | number> = {}

      if (theGroup.properties.settings) {
        const breakpointSettings = findBreakpointSettings(
          theGroup.properties.settings,
        ) as GroupSettingsInterface | null

        if (breakpointSettings) {
          if (breakpointSettings.position)
            styles.position = breakpointSettings.position

          if (breakpointSettings.displayMode)
            styles.display = breakpointSettings.displayMode.toString()

          styles = buildFlexStyles(styles, breakpointSettings)
          styles = buildGridStyles(styles, breakpointSettings)
          styles = buildMaxWidthStyles(styles, breakpointSettings)
          styles = buildMarginStyles(styles, breakpointSettings)
          styles = buildPaddingStyles(styles, breakpointSettings)
          styles = buildColorStyles(styles, breakpointSettings)
        }
      }

      groupStyles.value = styles
    }

    // Initial generation of styles
    generateGroupStyles()

    // Debounced updates to styles
    const updateGroupStyles = useDebounceFn(
      generateGroupStyles,
      10,
      { maxWait: 50 },
    )

    const generateGroupClasses = () => {
      const classes = {} as { [key: string]: boolean }
      let theGroup = Object.assign({}, props.group)

      if (props.group.id)
        theGroup = pageStore.findElement(props.group.id)?.element as GroupInterface

      if (!theGroup)
        return { properties: { settings: {} } }

      groupClasses.value = classes
    }

    const shouldContain = computed(() => {
      if (!props.group.properties.settings)
        return false

      const breakpointSettings = findBreakpointSettings(
        props.group.properties.settings,
      ) as GroupSettingsInterface | null

      return breakpointSettings?.contain
    })

    // Reset styles on window resize
    const { width: windowWidth, height: windowHeight } = useWindowSize()
    watch([windowWidth, windowHeight], () => {
      updateGroupStyles()
    })

    // Subscribe to changes in the store
    // pageStore.$subscribe((mutation, state) => {
    pageStore.$subscribe(() => {
      // TODO: This is a big one but this is firing on every slice in the page
      // To change this we'll need to fire a specific mutation on a specific slice
      // instead of updating the entire page. This will allow us to check the mutation events
      // and only update styles for the slice that needs it.

      // This will "speed up" and optimize the animations of changing background colors and such.
      updateGroupStyles()
    })

    watch(
      props.group,
      (value) => {
        if (value) {
          updateGroupStyles()
          generateGroupClasses()
        }
      },
      { deep: true },
    )

    const setSliceElement = (element: ElementInterface) => {
      return element as SliceInterface
    }

    return {
      props,
      root,
      groupStyles,
      groupClasses,
      setSliceElement,
      shouldContain,
      groupId,
    }
  },
})
</script>

<template>
  <div :id="groupId" :class="{ container: shouldContain }">
    <div :id="group.properties.id" ref="root" :style="groupStyles" :class="groupClasses">
      <template v-for="(element, key) in group.elements">
        <VoixSliceLoop
          v-if="element.type === 'slice'"
          :key="element.id"
          :element="setSliceElement(element)"
        />
        <VoixGroupChild v-if="element.type === 'group'" :key="key" :group="element" />
      </template>
    </div>
  </div>
</template>
