import { useNuxtApp, useRuntimeConfig } from '#imports'

export function useVoixConfig() {
  const getVoixConfig = () => {
    const { $voix } = useNuxtApp()
    return $voix.options
  }

  const getCurrentEnvironmentConfig = () => {
    const config = useRuntimeConfig()
    return config.public
  }

  const getCurrentDomain = (): string => {
    const config = useRuntimeConfig()

    // TODO if voixDomain isn't there then the browser domain needs to be used

    return config.public.voixDomain
  }

  return {
    getVoixConfig,
    getCurrentEnvironmentConfig,
    getCurrentDomain,
  }
}
